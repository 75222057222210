import axiosApi from '@/api/axiosApi';
import { SendReminderResponse } from '@/models/remind';

export const sendReminder = (): Promise<SendReminderResponse> => {
  return axiosApi.get('/timecard-reminder');
};

export const sendReminderCurrentWeek = (): Promise<SendReminderResponse> => {
  return axiosApi.get('/timecard-reminder-current-week');
};

// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import '@/pages/root-element/timecard-reminder-current-week/TimecardReminder.scss';
import { Button, message, Spin } from 'antd';
import { sendReminderCurrentWeek } from '@/api/timecardApi';

const TimecardReminder = () => {
  const [loading, setLoading] = useState(false);
  const sendTimecardReminder = async () => {
    setLoading(true);
    try {
      await sendReminderCurrentWeek();
      message.success('发送提醒成功');
    } catch (error) {
      message.error(`发送提醒失败 ${error?.message}`);
    }
    setLoading(false);
  };

  return (
    <div className="timecard-reminder-current-wek">
      <div className="timecard-reminder-title">提醒本周未填Timecard人员</div>
      <p className="timecard-reminder-info">确认提醒后，机器人所在企业微信群将会@本周未填Timecard 人员。无自动触发。</p>
      <Spin spinning={loading}>
        <Button type="primary" onClick={sendTimecardReminder} className={'send-btn'}>
          发送提醒
        </Button>
      </Spin>
    </div>
  );
};

export default TimecardReminder;

import { useLocation } from 'react-router-dom';
// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import '@/pages/root-element/RootElement.scss';
import { useCustomMatomo } from '@/matomo';
import { getToken } from '@/utils/localStorageUtils';
import NoPermission from '@/components/no-permission/NoPermission';
import TimecardReminder from '@/pages/root-element/timecard-reminder/TimecardReminder';
import TimecardReminderCurrentWeek from '@/pages/root-element/timecard-reminder-current-week/TimecardReminder';

const RootElement = () => {
  const location = useLocation();
  const { trackPageView } = useCustomMatomo();

  useEffect(() => {
    document.title = 'Wecom Timecard Reminder';
    trackPageView();
  }, [location]);

  const token = getToken();

  if (token) {
    return (
      <div className="root-element">
        <TimecardReminder />
        <TimecardReminderCurrentWeek />
      </div>
    );
  }
  return <NoPermission />;
};

export default RootElement;

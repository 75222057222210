import { createInstance, useMatomo } from '@jonkoops/matomo-tracker-react';
import { TrackEventParams } from '@jonkoops/matomo-tracker-react/lib/types';
import { debounce } from 'lodash-es';

const BaseURL = 'https://thoughtworks.innocraft.cloud/';

const siteId = process.env.REACT_APP_MATOMO_SITE_ID || '121';

export const matomoInstance = createInstance({
  urlBase: BaseURL,
  siteId: parseInt(siteId),
  linkTracking: false, // optional, default value: true
});

let globalDimension;

export function useCustomMatomo() {
  const matomoFuncs = useMatomo();
  return {
    ...matomoFuncs,
    trackEvent: debounce((params: TrackEventParams) => {
      matomoFuncs.trackEvent({
        ...params,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customDimensions: params.customDimensions ? [ ...(params.customDimensions as any), globalDimension ] : [ globalDimension ],
      });
    }),
    setGlobalDimension: dimension => {
      globalDimension = dimension;
    },
  };
}
class PerformanceTrack {
  timestamp: number;
  start() {
    this.timestamp = Date.now();
  }
  stop() {
    return Date.now() - this.timestamp;
  }
}

export const PerformanceTracker = new PerformanceTrack();

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { includes, omit } from 'lodash-es';
import { getToken } from '@/utils/localStorageUtils';
import { message } from 'antd';
import { redirect } from 'react-router-dom';

const getAxiosBaseUrl = () => process.env.REACT_APP_API_ADDRESS;

export const getWebBaseUrl = () => (process.env.NODE_ENV === 'development' ? 'http://localhost:3003' : process.env.REACT_APP_ADDRESS);

interface AxiosExtraRequestConfig {
  noNeedLoading?: boolean;
}

export const pickParams = ['url', 'method', 'params', 'data'];

interface IAxiosInstance extends AxiosInstance {
  // eslint-disable-next-line
  get<T = any, R = T, D = any>(url: string, config?: AxiosRequestConfig<D> & AxiosExtraRequestConfig): Promise<R>;

  // eslint-disable-next-line
  post<T = any, R = T, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D> & AxiosExtraRequestConfig): Promise<R>;
}

const axiosApi: IAxiosInstance = axios.create({
  baseURL: getAxiosBaseUrl(),
  timeout: 120000,
});

export const isStreamFileResp = (contentType: string): boolean => {
  const contentTypeList = ['application/octet-stream'];
  return includes(contentTypeList, contentType);
};

axiosApi.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return omit(config, 'noNeedLoading');
  },
  error => {
    return Promise.reject(error);
  },
);

axiosApi.interceptors.response.use(
  response => {
    if (isStreamFileResp(response.headers['content-type'])) {
      return response;
    }
    return response.data;
  },
  (error: AxiosError) => {
    const { response } = error;
    if (response.status >= 500) {
      message.error('系统异常，请联系管理员！');
    }
    if (response.status === 401) {
      message.error('权限校验失败');
    }
    if (response.status === 403) {
      redirect('/timecard-reminder/no-permission');
    }
    return Promise.reject(error);
  },
);

export default axiosApi;

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RouterObjectExtend } from '@/models/common';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { routerConfig } from '@/router';

function App() {
  const [routes, setRoutes] = useState<RouterObjectExtend[]>([]);

  useEffect(() => {
    setRoutes(routerConfig);
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      <div className="app-main" style={{ height: '100%' }}>
        {routes.length > 0 && (
          <RouterProvider
            router={createBrowserRouter(routes, {
              basename: window.__POWERED_BY_QIANKUN__ ? '/timecard-reminder' : '/',
            })}
          />
        )}
      </div>
    </ConfigProvider>
  );
}

export default App;
